import { Layout, LayoutProps } from '../models/layout';

import { Box } from '@chakra-ui/react';
import { ReactElement } from 'react';

const MainLayout: Layout = ({ children }: LayoutProps): ReactElement => {
  return (
    <Box h='calc(100% - 100px)' w='100%'>
      {children}
    </Box>
  );
};

export default MainLayout;
