import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import { AppRoutes } from './routers';

// 2. Extend the theme to include custom colors, fonts, etc
const colors = {
  primary: {
    '100': '#0B4582',
    '80': '#F6F9FF',
  },
  secondary: {
    '100': '#E94860',
  },
};

// 2. Update the breakpoints as key-value pairs
const breakpoints = {
  base: '0px',
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px',
};
const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
};

const theme = extendTheme({
  colors,
  breakpoints,
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'transparent',
              pointerEvents: 'none',
              color: '#9D9FA1',
              my: 2,
              transformOrigin: 'left top',
            },
          },
        },
      },
    },
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <AppRoutes />
    </ChakraProvider>
  );
}

export default App;
