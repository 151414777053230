import { Routes } from '../models/route';
import { lazy } from 'react';
import { path } from '../constants/path';

const PrivacyPolicyPage = lazy(() =>
  import('../pages/HomePage/components/PrivacyPolicy').then((p) => ({ default: p.default }))
);

export const PrivacyPolicyRoute: Routes = [
  {
    path: path.PRIVACY_POLICY,
    component: PrivacyPolicyPage,
  },
];
