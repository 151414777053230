import { Routes } from '../models/route';
import { lazy } from 'react';
import { path } from '../constants/path';

const HomePage = lazy(() =>
  import('../pages/HomePage').then((p) => ({ default: p.default }))
);

export const HomePageRoutes: Routes = [
  {
    path: path.HOME_PAGE,
    component: HomePage,
  },
];
