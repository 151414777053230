import { Routes } from '../models/route';
import { lazy } from 'react';
import { path } from '../constants/path';

const ErrorPage = lazy(() =>
  import('../pages/ErrorPage').then((p) => ({ default: p.default }))
);

export const ErrorPageRoutes: Routes = [
  {
    path: path.ERROR_PAGE,
    component: ErrorPage,
  },
];
