import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React, { ReactElement } from 'react';

import { ErrorPageRoutes } from './ErrorPage';
import { HomePageRoutes } from './HomePage';
import MainLayout from '../layouts/MainLayout';
import NotFoundPage from '../pages/NotFoundPage';
import { PrivacyPolicyRoute } from './PrivacyPolicyPage';
import { RouteType } from '../models/route';

const AppRoute = ({ component: Component }: RouteType): ReactElement => {
  return (
    <MainLayout>
      <Component />
    </MainLayout>
  );
};

const routes: RouteType[] = [
  ...HomePageRoutes,
  ...ErrorPageRoutes,
  ...PrivacyPolicyRoute,
];

export const AppRoutes = (): React.ReactElement => (
  <React.Suspense fallback={<MainLayout />}>
    <BrowserRouter >
      <Routes>
        {routes.map((r) => (
          <Route
            path={r.path}
            key={r.path}
            element={<AppRoute component={r.component} path={r.path} />}
          />
        ))}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  </React.Suspense>
);
