import { Box, Button, Heading, Text } from '@chakra-ui/react';

import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <Box textAlign="center" mt={20}>
      <Heading size="2xl">404 - Page Not Found</Heading>
      <Text mt={4} fontSize="xl">
        Oops! The page you are looking for does not exist.
      </Text>
      <Link to="/">
        <Button mt={6} >
          Go back to Home
        </Button>
      </Link>
    </Box>
  );
};

export default NotFoundPage;